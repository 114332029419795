import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import DataForm from 'forms/DataForm';
import { resetFormCache } from 'forms/formCache';
import { request } from 'utilities/graph';
import { getClientsByShortId } from 'graphql/queries';
import { createClient, updateClient, createFacilitySettings } from 'graphql/mutations';
import moment from 'moment';
import cache from 'utilities/cache';
import uiSchema from './uiSchema';

export default function FacilityClientForm({ ...props }) {
  // load here for translation purpose
  const { default: schema } = useMemo(() => require('./schema.js'), []);

  const getClientShortId = async () => {
    let shortId;
    while (!shortId) {
      const tempShortId = (Math.floor(Math.random() * 900000) + 100000).toString();
      const { data: { getClientsByShortId: { items } } } = await request(getClientsByShortId, { shortId: tempShortId });
      if (items.length === 0) {
        shortId = tempShortId;
      }
    }
    return shortId;
  };

  const createFunc = async (data) => {
    data.county = data.county || data.address.county;
    data.shortId = await getClientShortId();
    const now = moment().toISOString();
    const username = cache.get('app:username');
    const { data: { createFacilitySettings: facilitySettings } } = await request(createFacilitySettings, { input: {
      createdAt: now,
      createdBy: username,
      updatedAt: now,
      updatedBy: username,
    } });
    data.facilitySettingsId = facilitySettings.id;
    // backward compatibility
    data.useDedicatedDeliveryStaffs = data.featureConfiguration.useDedicatedDeliveryStaffs || false;
    await request(createClient, { input: data });
    resetFormCache('allFacilities');
    resetFormCache('allClients');
  };

  const updateFunc = async (data) => {
    if (!data.shortId) {
      data.shortId = await getClientShortId();
    }
    data.county = data.county || data.address.county;
    if (!data.featureConfiguration) {
      data.featureConfiguration = {
        skipRegisterSteps: [],
        skipDocumentsUpload: false,
        hideSToken: false,
        supportNumber: '',
        useDedicatedDeliveryStaffs: false,
        dementiaQuestionnaire: false,
        reportTypes: [],
      };
    }
    // backward compatibility
    data.useDedicatedDeliveryStaffs = data.featureConfiguration.useDedicatedDeliveryStaffs || false;

    const newData = JSON.parse(JSON.stringify(data));
    delete newData.facilitySettings;

    await request(updateClient, { input: newData });
    resetFormCache('allFacilities');
    resetFormCache('allClients');
  };

  return (
    <DataForm
      schema={schema}
      uiSchema={uiSchema}
      createFunc={createFunc}
      updateFunc={updateFunc}
      {...props}
    />
  );
}

FacilityClientForm.propTypes = {
  data: PropTypes.object,
  formData: PropTypes.object,
  onComplete: PropTypes.func,
};
