import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { getEventsByClientByTimestamp } from 'graphql/queries';
import DataTable from 'components/Table/DataTable';
import { TIME_ZONE } from '@silvergatedelivery/constants';
import { getClientIdSchema } from 'forms/schemas';

const title = '系統紀錄';
const description = '';

const ignoreDiffKey = [
  'createdAt',
  'createdBy',
  'updatedAt',
  'updatedBy',
];

const tableStyle = {
  border: '1px solid #cecece',
  borderCollapse: 'collapse',
  padding: 3,
};

const lastWeek = moment().tz(TIME_ZONE).add(-3, 'days').format('YYYY-MM-DD');
const today = moment().tz(TIME_ZONE).format('YYYY-MM-DD');

export default function EventTable({ clientId }) {
  const [indexes, setIndexes] = useState();
  const [queryParams, setQueryParams] = useState();

  const options = {};

  const columns = [
    {
      name: 'timestamp',
      label: '紀錄時間',
      type: 'datetime',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'tableName',
      label: '資料庫',
      options: {
        filter: true,
        sort: true,
        customBodyRender(value) {
          return value.split('-')[0];
        },
      },
    },
    {
      name: 'key',
      label: 'Key',
      options: {
        display: false,
        filter: false,
        sort: true,
        customBodyRender(value) {
          return value.split('__')[1];
        },
      },
    },
    {
      name: 'eventName',
      label: '類別',
      options: {
        filter: true,
        sort: true,
        customBodyRender(value) {
          switch (value) {
            case 'INSERT':
              return '新增';
            case 'MODIFY':
              return '更新';
            case 'REMOVE':
              return '刪除';
            default:
          }
        },
      },
    },
    {
      name: 'updatedBy',
      label: '更新者',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'diff',
      label: '變更內容',
      options: {
        filter: false,
        sort: false,
        customBodyRender(input) {
          let jsonObj = input;
          while (typeof jsonObj === 'string') {
            jsonObj = JSON.parse(jsonObj);
          }

          const diffs = jsonObj.filter(({ key }) => !ignoreDiffKey.includes(key));

          if (diffs.length === 0) return null;

          const displayValue = (value) => {
            if (typeof value === 'object') {
              return JSON.stringify(value, null, 2);
            }
            return String(value);
          };

          return (<table style={{ width: '100%', ...tableStyle }}>
            <thead>
              <tr>
                <th width="30%" style={tableStyle}>欄位</th>
                <th width="35%" style={tableStyle}>舊</th>
                <th width="35%" style={tableStyle}>新</th>
              </tr>
            </thead>
            <tbody>
              {diffs.map((diff, index)=>(
                <tr key={index}>
                  <td style={tableStyle}>
                    {diff.key}
                  </td>
                  <td style={tableStyle}>
                    <code>{displayValue(diff.old)}</code>
                  </td>
                  <td style={tableStyle}>
                    <code>{displayValue(diff.new)}</code>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>);
        },
      },
    },
    {
      name: 'oldData',
      label: '舊資料',
      type: 'json',
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'newData',
      label: '新資料',
      type: 'json',
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'expirationUnixTime',
      label: '到期時間',
      type: 'expirationUnixTime',
      options: {
        filter: false,
        sort: true,

      },
    },
  ];

  useEffect(() => {
    (async () => {
      const clientOptions = await getClientIdSchema(null, '所屬機構', true);

      setIndexes([
        {
          name: 'getEventsByClientByTimestamp',
          label: '按機構查詢',
          partitionKey: 'clientId',
          sortKey: 'timestamp',
          fields: [{
            label: '機構',
            key: 'clientId',
            type: 'select',
            options: clientOptions.map(({ id, name }) => ({ value: id, label: name })),
          }, {
            label: '紀錄時間',
            key: 'timestamp',
            type: 'datetime',
          }],
          operation: getEventsByClientByTimestamp,
        },
      ]);
      setQueryParams({
        clientId: clientOptions[0] && clientOptions[0].id,
        timestamp: {
          from: lastWeek,
          to: today,
        },
      });
    })();
  }, []);

  if (!indexes || !queryParams) return <></>;

  return (
    <React.Fragment>
      <DataTable
        title={title}
        description={description}
        themeProps={{
          cell: {
            minWidth: 48,
            maxWidth: 480,
          },
        }}
        columns={columns}
        options={options}
        defaultIndex={0}
        indexes={indexes}
        queryDefaultParams={queryParams}
      />
    </React.Fragment>
  );
}

EventTable.propTypes = {
  clientId: PropTypes.string,
};
