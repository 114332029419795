import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment-timezone';
import { utils, write } from 'xlsx';
import JSZip from 'jszip';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import { request, asyncListAll } from 'utilities/graph';
import { download } from 'utilities/file';
import {
  TIME_ZONE,
} from '@silvergatedelivery/constants';
import { formatAddress, formatAge, formatGender } from 'utilities/format';
import { getEldersByClientByStatus, getOrdersByClientByDate } from './queries';
import AutoSelect from 'forms/AdminBulkOrderForm/AutoSelect';
import { processShareOrder, processDisadvantagedTypes } from './CountyPrsReport/workbooks/helpers';

const DEFAULT_SERVICE_CODE = 'OT01';

const monthOptions = Array(10).fill('').map((x, index) => {
  return moment().add(-index, 'months').format('YYYY-MM');
});

const defaultLunchStartTime = '10:00';
const defaultLunchEndTime = '12:00';
const defaultDinnerStartTime = '16:00';
const defaultDinnerEndTime = '18:00';

// 衛福部支審系統報表
// Ministry of Health and Welfare Payment Review Report
export default function MohwPrsReport({ clientOptions = [] }) {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [selectedMonth, setSelectedMonth] = useState(monthOptions[0]);
  // const [clientIndex, setClientIndex] = useState(0);
  const [selectedClientId, setSelectedClientId] = useState();
  const [includeAllElders, setIncludeAllElders] = useState(false);
  const [includeUndeliveredOrders, setIncludeUndeliveredOrders] = useState(false);
  const [excludeNonFoodDeliveryOrders, setExcludeNonFoodDeliveryOrders] = useState(false);
  const [groupByDistrict, setGroupByDistrict] = useState(false);
  const [groupByAge, setGroupByAge] = useState(false);
  const [nameInNote, setNameInNote] = useState(false);
  const [deliveryByInServiceTime, setDeliveryByInServiceTime] = useState(false);
  const [useDeliveryStaffId, setUseDeliveryStaffId] = useState(false);
  const [displayExtraOptions, setDisplayExtraOptions] = useState(false);
  const [govQualifiedPrice, setGovQualifiedPrice] = useState(80);
  const [lunchStartTime, setlunchStartTime] = useState(defaultLunchStartTime);
  const [lunchEndTime, setlunchEndTime] = useState(defaultLunchEndTime);
  const [dinnerStartTime, setDinnerStartTime] = useState(defaultDinnerStartTime);
  const [dinnerEndTime, setDinnerEndTime] = useState(defaultDinnerEndTime);

  const collectData = async () => {
    const from = moment(selectedMonth).tz(TIME_ZONE).startOf('month');
    const to = moment(selectedMonth).tz(TIME_ZONE).endOf('month');
    const fromDate = from.format('YYYY-MM-DD');
    const toDate = to.format('YYYY-MM-DD');
    const fromISOString = from.toISOString();
    const toISOString = to.toISOString();
    const monthString = from.format('YYYY/MM');
    const period = `${fromDate}__${toDate}`;
    // const client = clientOptions[clientIndex];
    const client = clientOptions.find(({ id }) => id === selectedClientId);

    global.logger.debug({
      // clientIndex,
      selectedClientId,
      client,
      period,
      selectedMonth,
      fromDate,
      toDate,
      fromISOString,
      toISOString,
    });

    const allSummaryData = [];

    const deliveryStaffIds = [];

    const [
      elders,
      allOrders,
    ] = await Promise.all([
      asyncListAll(getEldersByClientByStatus, {
        clientId: client.id,
        status: includeAllElders ? undefined : { eq: '使用中' },
      }),
      asyncListAll(getOrdersByClientByDate, {
        clientId: client.id,
        date: {
          between: [fromDate, toDate],
        },
        limit: 1000,
      }),
    ]);

    allOrders.forEach(({ deliveryStaffId }) => {
      if (deliveryStaffId && !deliveryStaffIds.includes(deliveryStaffId)) {
        deliveryStaffIds.push(deliveryStaffId);
      }
    });

    global.logger.debug('deliveryStaffIds', deliveryStaffIds);

    const deliveryStaffs = await Promise.all(deliveryStaffIds.map(async (id) => {
      if (!useDeliveryStaffId) return {};

      const { data: { getDeliveryStaff: data } } = await request( /* GraphQL */ `
        query GetDeliveryStaff($id: ID!) {
          getDeliveryStaff(id: $id) {
            id
            name
            identificationCardId
          }
        }
      `, { id });
      return data;
    }));

    const summaryDataMapping = {};

    const zip = new JSZip();

    const zipDirs = {};

    let includedOrderStatus = ['delivered', 'completed'];
    if (includeUndeliveredOrders) {
      includedOrderStatus = includedOrderStatus.concat(['ready', 'readyForPickup', 'delivering']);
    }
    const filteredAllOrders = allOrders
      .filter(({ status }) => includedOrderStatus.includes(status));

    const shareOrderLog = processShareOrder(elders, filteredAllOrders);
    processDisadvantagedTypes(selectedMonth, elders);

    const getSanitizedElderName = (name) => name
      // .replace(/ /g, '')
      .replace(/\\/g, '')
      .replace(/\//g, '')
      .replace(/\?/g, '')
      .replace(/\*/g, '')
      .replace(/\[/g, '')
      .replace(/\]/g, '');
    const getRowsByElder = (elder) => {
      let rows = [];
      const { disadvantagedTypesSlot = [] } = elder;
      if (disadvantagedTypesSlot.length === 0) {
        disadvantagedTypesSlot.push({
          start: moment(selectedMonth).tz(TIME_ZONE).startOf('month').format('YYYY-MM-DD'),
          end: moment(selectedMonth).tz(TIME_ZONE).endOf('month').add(1, 'days').format('YYYY-MM-DD'),
          type: '未知',
        });
      }
      disadvantagedTypesSlot.forEach(({ start, end, type }) => {
        let orders = filteredAllOrders
          .filter(({ elderId }) => elderId === elder.id);
        if (excludeNonFoodDeliveryOrders) {
          orders = orders.filter(({ category }) => category === '送餐' || category === '送餐和物資');
        }
        orders = orders.filter(({ date }) => date >= start && date < end);
        if (orders.length === 0) {
          return;
        }
        const sanitizedElderName = getSanitizedElderName(elder.name);
        const getRowsByOrders = (orders) => {
          return orders
            .sort((a, b) => a.mealSlot < b.mealSlot ? 1 : -1)
            .sort((a, b) => a.deliveryBy > b.deliveryBy ? 1 : -1)
            .map((order) => {
              const deliveryBy = moment(order.deliveryBy).tz(TIME_ZONE);
              const [year, mmdd] = deliveryBy.format('YYYY-MMDD').split('-');
              const dateString = `${(parseInt(year) - 1911)}${mmdd}`;
              const deliveryStaff = deliveryStaffs.find(({ id }) => id === order.deliveryStaffId);

              let serviceStartHour =
                order.mealSlot === 'lunch' ? parseInt(lunchStartTime.split(':')[0]) : parseInt(dinnerStartTime.split(':')[0]);
              let serviceStartMinute =
                order.mealSlot === 'lunch' ? parseInt(lunchStartTime.split(':')[1]) : parseInt(dinnerStartTime.split(':')[1]);
              let serviceEndtHour =
                order.mealSlot === 'lunch' ? parseInt(lunchEndTime.split(':')[0]) : parseInt(dinnerEndTime.split(':')[0]);
              let serviceEndMinute =
                order.mealSlot === 'lunch' ? parseInt(lunchEndTime.split(':')[1]) : parseInt(dinnerEndTime.split(':')[1]);
              if (deliveryByInServiceTime) {
                // const offset = (order.direction && order.direction.durationInSeconds) ? order.direction.durationInSeconds : 0;
                const offset = 0; // 秉鑫決定不套用運送時間
                const serviceStart = moment(order.deliveryBy).add(offset, 'second').tz(TIME_ZONE);
                serviceStartHour = serviceStart.hour();
                serviceStartMinute = serviceStart.minute();
                const serviceEnd = moment(order.deliveryBy).add(30 * 60 + offset, 'second').tz(TIME_ZONE);
                serviceEndtHour = serviceEnd.hour();
                serviceEndMinute = serviceEnd.minute();
              }
              return {
                '身分證字號': (elder.identificationCardId || '').toUpperCase(),
                '服務日期(請輸入7碼)': dateString, // ex: 107010 民國
                '服務項目代碼': elder.serviceCode || DEFAULT_SERVICE_CODE, // ex OT01
                '服務類別\n1.補助\n2.自費': 1, // elder.isDisadvantaged ? 1 : 2,
                '數量\n(僅整數)': 1, // order.mealItems.reduce((sum, { quantity }) => sum + quantity, 0),
                '單價': govQualifiedPrice || order.total, // 核定單價
                '服務人員身分證': useDeliveryStaffId ?
                  deliveryStaff.identificationCardId : (elder.supervisorId || '督導身分證字號'),
                '起始時段-小時\n(24小時制)': serviceStartHour,
                '起始時段-分鐘': serviceStartMinute,
                '結束時段-小時\n(24小時制)': serviceEndtHour,
                '結束時段-分鐘': serviceEndMinute,
                // below items are not required
                '備註': nameInNote ? sanitizedElderName : '',
                '服務人員身分證2': '',
                '服務人員身分證3': '',
                '服務人員身分證4': '',
                '服務人員身分證5': '',
                '不申報AA09填1': '',
                '訪視未遇填1': '',
                'C碼必填-復能目標達成情形\n1.尚未滿服務組數\n2.滿服務組數且已達復能目標\n3.滿服務組數但尚未達復能目標\n4.未滿服務組數已結案，且已達復能目標\n5.未滿服務組數已結案，但未達復能目標': '',
                'C碼必填-復能目標': '',
                'C碼必填-指導對象': '',
                'C碼必填-服務內容': '',
                'C碼必填-指導建議摘要': '',
                'OT01必填-餐別\n1.早餐\n2.午餐\n3.晚餐': order.mealSlot === 'lunch' ? 2 : 3,
                // 07/02/2023 支審系統新增欄位
                'BD03、DA01使用-出發地': '',
                'BD03、DA01使用-目的地': '',
                'BD03、DA01使用-出發地(經度)': '',
                'BD03、DA01使用-出發地(緯度)': '',
                'BD03、DA01使用-目的地(經度)': '',
                'BD03、DA01使用-目的地(緯度)': '',
                'BD03、DA01使用-里程數(公里)': '',
                'BD03、DA01使用-車號': '',
                // V13新增
                'BD03必填-服務使用類型\n1.社區式長照機構\n2.社區服務據點(不含身障類)\n3.輔具中心\n4.身障日間照顧服務': '',
              };
            });
        };
        rows = rows.concat(getRowsByOrders(orders));
      });
      return rows;
    };
    const ageBase = moment(selectedMonth).tz(TIME_ZONE).endOf('month').valueOf();
    if (!groupByDistrict) {
      if (groupByAge) {
        const eldersGroupByAge = elders.reduce((acc, elder) => {
          const age = formatAge(elder.birthday, ageBase);
          let key = '65歲以下';
          if (age >= 65) {
            key = '65歲以上';
          }
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(elder);
          return acc;
        }, {});
        Object.keys(eldersGroupByAge).forEach((key) => {
          const elders = eldersGroupByAge[key];
          let allRows = [];
          elders.sort((a, b) => a.name > b.name ? 1 : -1).forEach((elder) => {
            allRows = allRows.concat(getRowsByElder(elder));
          });
          const worksheet = utils.json_to_sheet(allRows);
          const workbook = utils.book_new();
          utils.book_append_sheet(workbook, worksheet, `${key}核銷報表`);
          const wbout = write(workbook, { bookType: 'xls', bookSST: true, type: 'binary' });

          zip.file(`${key}.xls`, wbout, { binary: true });
        });
      } else {
        // 一位長輩一個檔案
        elders.sort((a, b) => a.name > b.name ? 1 : -1).forEach((elder) => {
          const rows = getRowsByElder(elder);
          // \ / ? * [ ]
          const sanitizedElderName = getSanitizedElderName(elder.name);

          const worksheet = utils.json_to_sheet(rows);
          const workbook = utils.book_new();
          utils.book_append_sheet(workbook, worksheet, `${sanitizedElderName} 核銷報表`);
          const wbout = write(workbook, { bookType: 'xls', bookSST: true, type: 'binary' });

          // 依照督導分裝
          if (elder.supervisorId) {
            if (!zipDirs[elder.supervisorId]) {
              zipDirs[elder.supervisorId] = zip.folder(elder.supervisorId);
            }
            zipDirs[elder.supervisorId].file(`午晚-${sanitizedElderName}.xls`, wbout, { binary: true });
          } else {
            zip.file(`午晚-${sanitizedElderName}.xls`, wbout, { binary: true });
          }
        });
      }
    } else { // 一個鄉鎮一個檔案
      const eldersGroupByDistrict = elders.reduce((acc, elder) => {
        const key = elder.address.district;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(elder);
        return acc;
      }, {});
      Object.keys(eldersGroupByDistrict).sort().forEach((district) => {
        const elders = eldersGroupByDistrict[district];
        if (groupByAge) {
          const eldersGroupByAge = elders.reduce((acc, elder) => {
            const age = formatAge(elder.birthday, ageBase);
            let key = '65歲以下';
            if (age >= 65) {
              key = '65歲以上';
            }
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(elder);
            return acc;
          }, {});
          Object.keys(eldersGroupByAge).forEach((key) => {
            const elders = eldersGroupByAge[key];
            let allRows = [];
            elders.sort((a, b) => a.name > b.name ? 1 : -1).forEach((elder) => {
              allRows = allRows.concat(getRowsByElder(elder));
            });
            const worksheet = utils.json_to_sheet(allRows);
            const workbook = utils.book_new();
            utils.book_append_sheet(workbook, worksheet, `${district}(${key})核銷報表`);
            const wbout = write(workbook, { bookType: 'xls', bookSST: true, type: 'binary' });

            zip.file(`${district}(${key}).xls`, wbout, { binary: true });
          });
        } else {
          let allRows = [];
          elders.sort((a, b) => a.name > b.name ? 1 : -1).forEach((elder) => {
            allRows = allRows.concat(getRowsByElder(elder));
          });

          const worksheet = utils.json_to_sheet(allRows);
          const workbook = utils.book_new();
          utils.book_append_sheet(workbook, worksheet, `${district} 核銷報表`);
          const wbout = write(workbook, { bookType: 'xls', bookSST: true, type: 'binary' });

          zip.file(`${district}.xls`, wbout, { binary: true });
        }
      });
    }

    // @送餐服務＿服務明細
    elders.sort((a, b) => a.name > b.name ? 1 : -1).forEach((elder) => {
      const { disadvantagedTypesSlot = [] } = elder;
      if (disadvantagedTypesSlot.length === 0) {
        disadvantagedTypesSlot.push({
          start: moment(selectedMonth).tz(TIME_ZONE).startOf('month').format('YYYY-MM-DD'),
          end: moment(selectedMonth).tz(TIME_ZONE).endOf('month').add(1, 'days').format('YYYY-MM-DD'),
          type: '未知',
        });
      }
      disadvantagedTypesSlot.forEach(({ start, end, type }) => {
        let orders = filteredAllOrders
          .filter(({ elderId }) => elderId === elder.id)
          .filter(({ status }) => includedOrderStatus.includes(status));
        if (excludeNonFoodDeliveryOrders) {
          orders = orders.filter(({ category }) => category === '送餐' || category === '送餐和物資');
        }
        orders = orders.filter(({ date }) => date >= start && date < end);
        if (orders.length === 0) {
          return;
        }

        const identificationCardId = elder.identificationCardId || elder.id;
        const serviceCode = elder.serviceCode || DEFAULT_SERVICE_CODE;

        const key = `${identificationCardId}__${serviceCode}__${type}`;

        let cost = 0;
        let price = 0;
        if (orders.length > 0) {
          if (orders[0].mealItems?.length > 0) {
            cost = orders[0].mealItems[0].cost || 0;
            price = orders[0].mealItems[0].price || 0;
          }
        }
        const data = summaryDataMapping[key] || {
          '繳費月份': monthString,
          '狀態': elder.status || '使用中',
          '姓名': elder.name,
          '身分證字號': identificationCardId,
          '性別': formatGender(elder.gender),
          '生日': (elder.birthday || ''),
          '年齡': formatAge(elder.birthday, ageBase),
          '身份別': type,
          '項次': serviceCode,
          '項目': '送餐',
          '進價': cost,
          '單價': price,
          '核定單價': govQualifiedPrice || (orders.length > 0 ? orders[0].total : 0),
          '核定量': elder.govQualifiedCount || 0,
          '服務量': 0,
          '午餐量': 0,
          '晚餐量': 0,
          '地址': formatAddress(elder.address),
          '家庭代碼': elder.linkGroupName || '',
          '合併資料': '',
        };

        // combine elder with the same identificationCardId;
        data['服務量'] += orders.length;
        data['合併資料'] += `${elder.name}(${orders.length}),`;

        orders.forEach((order) => {
          if (order.mealSlot === 'lunch') {
            data['午餐量'] += 1;
          } else {
            data['晚餐量'] += 1;
          }
        });

        if (!displayExtraOptions) {
          delete data['性別'];
          delete data['生日'];
          delete data['年齡'];
          delete data['身份別'];
          delete data['午餐量'];
          delete data['晚餐量'];
        }

        summaryDataMapping[key] = data;
        allSummaryData.push(data);
      });
    });

    const worksheet = utils.json_to_sheet(Object.keys(summaryDataMapping).map((key) => summaryDataMapping[key]));
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, `送餐服務＿服務明細`);
    const wbout = write(workbook, { bookType: 'xlsx', bookSST: true, type: 'binary' });
    zip.file(`@送餐服務＿服務明細.xlsx`, wbout, { binary: true });

    if (shareOrderLog && shareOrderLog.length !== 0) {
      const worksheet = utils.json_to_sheet(Object.keys(shareOrderLog).map((key) => shareOrderLog[key]));
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, '家庭代碼log');
      const wbout = write(workbook, { bookType: 'xlsx', bookSST: true, type: 'binary' });
      zip.file(`shareOrderLog.xlsx`, wbout, { binary: true });
    }

    const zipContent = zip.generate({ type: 'blob' });
    download(`${client.name}__${selectedMonth}.zip`, zipContent);
  };

  const triggerDownload = async () => {
    try {
      setMessage('');
      setIsLoading(true);
      await collectData();
    } catch (e) {
      global.logger.debug(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (clientOptions[0]) {
      setSelectedClientId(clientOptions[0].id);
      setGovQualifiedPrice(clientOptions[0].facilitySettings.govQualifiedPrice || 80);
      setlunchStartTime(clientOptions[0].facilitySettings.lunchStartTime || defaultLunchStartTime);
      setlunchEndTime(clientOptions[0].facilitySettings.lunchEndTime || defaultLunchEndTime);
      setDinnerStartTime(clientOptions[0].facilitySettings.dinnerStartTime || defaultDinnerStartTime);
      setDinnerEndTime(clientOptions[0].facilitySettings.dinnerEndTime || defaultDinnerEndTime);
    }
  }, [clientOptions]);

  return (
    <div>
      <h3>衛福部支審系統：送餐服務服務明細報表輸出</h3>
      <div>
        <FormControl style={{ minWidth: 360, marginRight: 16 }}>
          {/* <InputLabel id="selected-client">機構</InputLabel>
          <Select
            labelId="selected-client-label"
            id="selected-client"
            value={clientIndex}
            onChange={(event) => setClientIndex(event.target.value)}
            disabled={isLoading}
          >
            {clientOptions.map((item, index) => (
              <MenuItem key={index} value={index}>
                {item.name}
              </MenuItem>
            ))}
          </Select> */}
          <AutoSelect
            id="selected-restaurant"
            options={clientOptions.map(({ name }) => name)}
            values={clientOptions.map(({ id }) => id)}
            required={true}
            value={selectedClientId}
            label="機構"
            onChange={(value) => {
              setSelectedClientId(value);
              const client = clientOptions.find(({ id }) => id === value);
              if (client) {
                setGovQualifiedPrice(client.facilitySettings.govQualifiedPrice || 80);
                setlunchStartTime(client.facilitySettings.lunchStartTime || defaultLunchStartTime);
                setlunchEndTime(client.facilitySettings.lunchEndTime || defaultLunchEndTime);
                setDinnerStartTime(client.facilitySettings.dinnerStartTime || defaultDinnerStartTime);
                setDinnerEndTime(client.facilitySettings.dinnerEndTime || defaultDinnerEndTime);
              }
            }}
          />
        </FormControl>
        <FormControl style={{ width: 150, marginRight: 16 }}>
          <InputLabel id="selected-month">月份</InputLabel>
          <Select
            labelId="selected-month-label"
            id="selected-month"
            value={selectedMonth}
            onChange={(event) => setSelectedMonth(event.target.value)}
            disabled={isLoading}
          >
            {monthOptions.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="核定單價"
          type="number"
          value={govQualifiedPrice}
          onChange={(e) => setGovQualifiedPrice(e.target.value)}
          required
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={includeAllElders}
              name={'includeAllElders'}
              value={includeAllElders}
              disabled={!selectedClientId || isLoading}
              onChange={(e) => {
                setIncludeAllElders(e.target.checked);
              }}
            />
          }
          label={'包含已停用的個案'}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={useDeliveryStaffId}
              name={'useDeliveryStaffId'}
              value={useDeliveryStaffId}
              disabled={!selectedClientId || isLoading}
              onChange={(e) => {
                setUseDeliveryStaffId(e.target.checked);
              }}
            />
          }
          label={'帶入送餐大使至服務人員身分證'}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={displayExtraOptions}
              name={'displayExtraOptions'}
              value={displayExtraOptions}
              disabled={!selectedClientId || isLoading}
              onChange={(e) => {
                setDisplayExtraOptions(e.target.checked);
              }}
            />
          }
          label={'顯示性別/年齡/身份別/午晚餐服務量'}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={includeUndeliveredOrders}
              name={'includeUndeliveredOrders'}
              value={includeUndeliveredOrders}
              disabled={!selectedClientId || isLoading}
              onChange={(e) => {
                setIncludeUndeliveredOrders(e.target.checked);
              }}
            />
          }
          label={'包含未送達的訂單'}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={excludeNonFoodDeliveryOrders}
              name={'excludeNonFoodDeliveryOrders'}
              value={excludeNonFoodDeliveryOrders}
              disabled={!selectedClientId || isLoading}
              onChange={(e) => {
                setExcludeNonFoodDeliveryOrders(e.target.checked);
              }}
            />
          }
          label={'排除非送餐的訂單'}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={groupByDistrict}
              name={'groupByDistrict'}
              value={groupByDistrict}
              disabled={!selectedClientId || isLoading}
              onChange={(e) => {
                setGroupByDistrict(e.target.checked);
              }}
            />
          }
          label={'以鄉鎮市區歸檔'}
        />
        <Tooltip title={'依照送餐對象年齡歸檔，分為65歲以上與65歲以下'}>
          <FormControlLabel
            control={
              <Checkbox
                checked={groupByAge}
                name={'groupByAge'}
                value={groupByAge}
                disabled={!selectedClientId || isLoading}
                onChange={(e) => {
                  setGroupByAge(e.target.checked);
                }}
              />
            }
            label={'依年齡歸檔'}
          />
        </Tooltip>
        <FormControlLabel
          control={
            <Checkbox
              checked={nameInNote}
              name={'nameInNote'}
              value={nameInNote}
              disabled={!selectedClientId || isLoading}
              onChange={(e) => {
                setNameInNote(e.target.checked);
              }}
            />
          }
          label={'備註填入姓名'}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={deliveryByInServiceTime}
              name={'deliveryByInServiceTime'}
              value={deliveryByInServiceTime}
              disabled={!selectedClientId || isLoading}
              onChange={(e) => {
                setDeliveryByInServiceTime(e.target.checked);
              }}
            />
          }
          label={'服務時間套用訂單時間'}
        />
      </div>
      <Button
        variant="outlined"
        onClick={triggerDownload}
        disabled={!selectedClientId || isLoading}
        style={{ marginTop: 16 }}
      >
        下載
        {isLoading && <CircularProgress color="primary" size={16} style={{ marginLeft: 8 }} />}
      </Button>
      <p>
        {message}
      </p>
    </div>
  );
}

MohwPrsReport.propTypes = {
  clientOptions: PropTypes.array,
};
